import { useStaticQuery, graphql } from 'gatsby'

export const QueryContact = () => {
  const query = useStaticQuery(
    graphql`
      {
        sanityContact {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
        sanityConfig {
          id
          internal {
            type
            content
            contentDigest
            owner
          }
        }
      }
    `
  )
  const contact = JSON.parse(query.sanityContact.internal.content)
  const config = JSON.parse(query.sanityConfig.internal.content)
  return Object.assign({} , contact, config)
}
