import React from 'react'

class TypingText extends React.Component {
  constructor () {
    super()
    this.type = React.createRef()
  }
  componentDidMount () {
    const Typed = require('typed.js')
    var options = {
      strings: this.props.text,
      typeSpeed: 40,
      backSpeed: 40,
      startDelay: 2000,
      backDelay: 2000,
      loop: true,
      smartBackspace: false
    }
    /* eslint-disable */
    var typed = new Typed(this.type.current, options)
    /* eslint-enable */
  }
  render () {
    return (
      <h2 className='inline-block mt0 pt0 rel'>
        <span className='light' ref={this.type} />
      </h2>
    )
  }
}

export default TypingText
