import React from 'react'
import { StaticMap } from 'react-map-gl'
import { AutoSizer } from 'react-virtualized'

import 'mapbox-gl/dist/mapbox-gl.css'

const ContactMap = ({ location }) => {
  const mapPins = {
    viewport: {
      latitude: location.geolocation.lat,
      longitude: location.geolocation.lng,
      zoom: 14
    },
    settings: {
      scrollZoom: false
    },
    pins: [],
    scriptLoaded: false
  }
  return (
    <div className='map mt1 mb1 x'>
      <AutoSizer>
        {({ height, width }) => {
          return (
            <div className='map'>
              <StaticMap {...mapPins.viewport} height={height} width={width}
                {...mapPins.settings}
                mapStyle='mapbox://styles/grandarmy/ck6mdn9oj11uh1ipfm9p4nc1a'
                mapboxApiAccessToken='pk.eyJ1Ijoic2NvdHRzYW5kZXJzIiwiYSI6ImNqczIzYjgybjAxZGU0OW1xdnhhOXZldTUifQ.xE7GuKOEq_rD20ZY_aJ_pA'>
                <div className='abs x y top left f jcc aic'>
                  <svg className='map__pin' viewBox='0 0 686.56 1123.15'>
                    <g>
                      <path fill='currentColor' class='st5' d='M343.28,0C153.69,0,0,153.69,0,343.28c0,2.53,0.04,5.04,0.1,7.55c0.02,1.04,0.05,2.08,0.09,3.11
                        c0.04,1.21,0.09,2.42,0.14,3.62c1.17,28.64,5.85,56.37,13.62,82.78c59.96,211.78,293.55,382.56,305.8,660.07
                        c0.56,12.68,10.85,22.73,23.54,22.73s22.98-10.06,23.54-22.73c12.25-277.51,245.84-448.29,305.8-660.07
                        c7.77-26.41,12.45-54.14,13.62-82.78c0.05-1.21,0.1-2.41,0.14-3.62c0.03-1.04,0.06-2.07,0.09-3.11c0.06-2.51,0.1-5.03,0.1-7.55
                        C686.56,153.69,532.87,0,343.28,0z M343.28,465.29c-68.9,0-124.75-55.85-124.75-124.75s55.85-124.75,124.75-124.75
                        c68.9,0,124.75,55.85,124.75,124.75S412.18,465.29,343.28,465.29z'/>
                    </g>
                  </svg>
                </div>
              </StaticMap>
            </div>
          )
        }}
      </AutoSizer>
    </div>
  )
}

export default ContactMap
