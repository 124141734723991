import React from 'react'
import cx from 'classnames'
import BlockContent from '@sanity/block-content-to-react'

import META from '@/meta'
import store from '../state/store.js'
import Footer from '@/footer.js'
import MiscCarousel from '@/modules/miscCarousel.js'
import { QueryContact } from '../utls/queryContact.js'
import ContactMap from '@/misc/map.js'
import CurrentTime from '@/misc/currentTime.js'
import TypingText from '@/misc/typingText.js'
import { Projects, LongArrow } from '@/svgs.js'
import { linkSerializer } from '@/utls.js'

const updateStates = () => {
  store.hydrate({
    projectNav: !store.state.projectNav
  })()
  const html = document.querySelector('html')
  html.classList.add('opened')
}

const Contact = ({ transitionStatus, entry, exit }) => {
  const data = QueryContact()
  return (
    <div className={cx('contactContain animate__page', transitionStatus)}>
      <META data={data} url={'contact'} />
      <div className='nav__push contact' />
      <div className='container--xl ma x p1 projectNavContain contactSpacer'>
        <div className='f jcs aic work__landing-nav' onClick={updateStates}>
          <Projects /><h4 className='p0 m0 mono h5 caps'>Projects</h4>
        </div>
      </div>
      <MiscCarousel content={data.carousel} />
      <div className='container--xl ma px15 x f jcb aic contact__location-top fw  pt1'>
        {data.locations.map((location, i) => (
          <div key={i} className='contact__location f jcb ais'>
            <div>
              <h5 className='caps mt0 pt0 mono '>Office</h5>
              <h2 className='mt0 pt0 mb0 pb0 light'>
                {location.title}<br />
                <CurrentTime zone={location.timeZone} />
              </h2>
            </div>
            <div className='ml1 pl1'>
              <h5 className='mono caps mt0 pt0'>Visit</h5>
              <a href={location.locationLink} target='_blank' rel='noopener noreferrer' className='underline contact__link'>
                <span className='mt0 pt0 mb0 h4 inline' dangerouslySetInnerHTML={{__html: location.location.replace(/[\r\n]+/g, '<br />')}} /><LongArrow className='inline' />
              </a>
            </div>
          </div>
        ))}
      </div>
      <div className='container--xl ma contact__location-top px15'>
        <div className='f jcb ais fw contact__divider'>
          <div className='x f jcs ais'>
            <TypingText text={data.blinking} /> <h2 className='mt0 pt0 light'><span className='light'>&nbsp;</span></h2>
          </div>
          <div className='x'>
            <h5 className='caps mono mt0 pt0'>Contact</h5>
            <BlockContent blocks={data.contactInformation} serializers={linkSerializer} />
          </div>
        </div>
      </div>
      <div className='container--xl ma px15 map__wrapper x f jcb fw'>
        {data.locations.map((location, i) => (
          <ContactMap key={i} location={location} />
        ))}
      </div>
      <Footer />
    </div>
  )
}

export default Contact
